a {
  color: rgb(65, 199, 199);
  text-decoration: none;
  font-weight: 600;
}
a:hover {
  text-decoration: underline;
}

h1 {
  color: rgb(248, 159, 159);
}

h3 {
  border-top: 1px solid #4d4f4f;
  padding-top: 1em;
}

.App {
  font-family: 'IBM Plex Mono', monospace;
  text-align: center;
  background: rgb(33, 36, 36);
  position: absolute;
  width: 100%;
  height: 100%;
}

.tm-map {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.App-header {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  color: rgb(225, 225, 225);
}

.App-header-content {
  background: #333;
  padding: 2px 20px;
}

.App-menu-handler a {
  background: #333;
  padding: 10px 20px;
  font-size: 2em;
}

.App-sidebar {
  background: #333;
}
.App-sidebar-wrapper {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.App-sidebar a,
.App-sidebar div {
  padding: 5px 10px;
  margin: 10px;
  display: block;
  color: rgb(225, 225, 225);
}
.App-sidebar a.inactive {
  background: #555;
}
.App-sidebar a.active {
  background: rgb(65, 199, 199);
  color: #333;
}

.App-symbology-low {
  background: rgb(254, 106, 106);
}
.App-symbology-high {
  background: rgb(65, 199, 199);
}

@media screen and (max-width: 600px) {
  body {
    font-size: 0.6rem;
  }
}
